import React from "react";
import {Helmet} from "react-helmet";

export default function Radical() {
    return (
        <div>
            <Helmet>
                <title>一种强烈的实用主义：读William James | A Strong Pragmatism: Reading William James
                </title>
            </Helmet>
            <h6 className="App-essay-title">一种强烈的实用主义：读William James</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二四 十二月</b>
                <br/><br/>
                这篇文章是我旅行中的读书笔记。
                <br/><br/>
                里面大量引用了William James的观点和哲学。如果对实用主义哲学怀有热情或好奇，去读William
                James，他的思想会给你展现一个全新的世界。
                <br/><br/><br/>
                <h6><b>哲学的气质</b></h6>
                <br/>
                人的认知过程基于偏见——一种独特的个人气质和视角——是瞪大眼睛看时获得的第一手基本事实。而这种事实被刻意掩盖，掩盖的借口经常是绝对客观的理性原则，认为世界是单一的、一元的、由绝对规律构成的。
                <br/><br/>
                但如果你仔细考察，所有人的“客观”程度都不相同，我们由一群类似但绝不相同的鲜明个体构成，没有两片树叶是一模一样的。这些特有的纹理脉络构成了独特的气质。
                <br/><br/>
                哲学思考的历史就极大程度地构建于这种与众不同的气质、审美和思维偏好，其本质都是对于世界一无所知的理解——无论理智主义（有些是“反智主义”）虚构了多少精美的抽象结构，还是经验主义者创造的那些真实的悲观虚无。
                <br/><br/>
                哲学冲突源于哲学家个人气质的冲突，这点很少被人们提及。因为这个分类显得过于粗制滥造。
                <br/><br/>
                但从这个角度思考，整个哲学拥有两种极端相反的气质，这两种气质涵盖了所有哲学家和哲学内容，以至于任何哲学流派都是这两种极端气质以不同比例混合的产物。
                <br/><br/>
                人们可以指责分类过于简陋，但人们无法否定自己最真实的感觉——那种感觉支配了你对任何哲学所产生的第一印象，和最后一个印象：
                <br/><br/>
                <b><i>-理性主义——理智——逻辑——结构——纯粹——理想——封闭式——独断论——自由意志——抽象的——一元论——温和的……</i></b>
                <br/><br/>
                <b><i>-经验主义——感觉——事实——现象——庞杂——实践——开放式——怀疑论——宿命未知——事实的——多元论——刚健的……</i></b>
                <br/><br/>
                你脑海中对任何一个哲学家和哲学流派的印象，一定与上述某种气质挂钩，过往的哲学都可以由这两种气质调配而成。
                <br/><br/><br/>
                <h6><b>关注事实</b></h6>
                <br/>
                一种结构精巧的思想体系无论多么精致，其内在都是封闭的，因为构建这种结构的人力是有限的，注定对特定事实进行有选择地忽视，摒弃和抽象化。这种对开放宇宙的视而不见，导致了纯粹理性的孱弱无能。
                <br/><br/>
                在W. James看来，斯宾塞不断引证事实、强调事实，即使偶尔牵强附会，但这也是正确对待事物的方式，这已经充分修正了纯粹理性主义的痼疾。James认为，人的活动高于一切，高于一切理性法则，也高于上帝。人需要哲学，如同人需要面包，所以哲学家应该像制造面包一样建造哲学，使之实用于人。建造庄严精美但啃不动的象牙宫殿，是理性主义的软肋。
                <br/><br/>
                对事实的关注只是方法，关注事实可以导向无数目的、理论、抽象、更深层次的事实，等等——这是实用主义包罗万象的开放宇宙，它调和了理性和经验的争执。
                <br/><br/>
                经过科学革命和启蒙运动的现代西方人，比我们想象得更唯物主义，也更实用。中国传统的经院哲学，其理性主义成分更纯粹，更具有强烈的宗教色彩。身处洞穴之中，我们都更难认识到有悖直觉的现象。
                <br/><br/><br/>
                <h6><b>实用主义</b></h6>
                <br/>
                W. James在《实用主义-某些旧思想方法的新名称》中举了一个生动无比的例子：
                <br/><br/>
                <i style={{color: 'grey'}}>
                    德国莱比锡著名化学家奥斯特瓦尔德，在他关于科学哲学的讲演中就已经完全明确地使用了实用主义原理；虽然他没有使用这个名词。
                    <br/><br/>
                    奥斯特瓦尔德曾写信给我说：“一切实在都影响我们的实践：这种影响对我们来说就是实在的意义。我习惯于在班上向学生提出这样的问题：如果两个可能的观点中这个或那个是真的，那么世界在什么方面会有所不同呢？如果我们找不出什么不同，那么这两种选择就是没有任何意义的了。”
                    <br/><br/>
                    那就是说，两个互相对立的观点，在实际上意味着同样的东西；而对我们来说，除了实际的意义以外，并没有任何别的意义可言。奥斯特瓦尔德在一篇公开发表的讲演中举了这样一个例子说明他的意义。化学家们长久以来就为化学上称之为“互变异构（tautomerous）”的物体的内部构成争论不休。这些物体的属性似乎与这样的一个概念相一致：即在这些物体中有不稳定的氢原子来回振动着，或者是两种物体的不稳定的混合物。两种观点争论激烈，但从没有解决。奥斯特瓦尔德说：“争论双方如果先问问自己，要是这个观点或那个观点正确，在具体的实验事实上会有什么差别呢？如果这么一问，争论便根本不会开始。因为，如果那样问，就会显出没有任何事实上的差别会发生；因而这个争论是不真实的，就好像原始时代的人们讨论用发酵剂发面的原理时，一派人说发酵现象的真实原因是由于招来了‘粽仙’，另一派人则坚持说是一种‘灵气’，这种争论是不真实的一样。”
                </i>
                <br/><br/>
                “粽仙”，“灵气”，聪明的读者会想起类似的现代理论而笑出声。
                <br/><br/>
                类似的，法国科学家萨迪·卡诺使用"热质说"（一种今天认为是谬误的不存在物质），成功地解释了热力循环的原理和热力学第一定律——热量和做工的能力之间存在恒定关系。
                <br/><br/>
                使用一种错误的假设（今天观点），推导出正确的科学原理——这几乎可以看作是运用实用主义方法的经典案例——穿过哪个房间来到此处并没有那么重要，如果你最后到达的地方是你所需要的——是为真的——那么你就已经在对的地方了。
                <br/><br/>
                假设、定律、公理——这些东西是四通八达的走廊，目的是到达某个对你有用的房间。它们是方法和手段，不是目的地。真理不是绝对的，真理是相对的和不断改良的，一种拥有绝对真理主张的理性主义，或许并没有人们认为的那么正确，只不过这类主张经常故作强硬自信罢了。
                <br/><br/><br/>
                <h6><b>思维实验</b></h6>
                <br/>
                让我们用今天划时代的人工智能技术做一个思维实验。
                <br/><br/>
                <b><i>实验一：什么人工智能工具是Copilot（副驾驶），什么人工智能可被称作Agent（智能代理）？</i></b>
                <br/><br/>
                两个定义：
                <br/>
                1. Copilot：在操作过程中，AI辅助人类完成工作，但依然需要人类的操作和判断。
                <br/>
                2. Agent：计算机全权代理人类活动，无需人类的操作介入就能自主完成全部工作。
                <br/><br/>
                让我们对这两个观点进行经验而非理性的分析：如果AI能不需要人类输入、全自动地执行代理，才配叫做Agent智能代理，那么多大成分的全自动才配算作全自动？
                <br/><br/>
                -是否连人按下停止、开始的按钮都不需要才算？<br/>
                -还是人类甚至不需要提供新算法和软件更新才算？<br/>
                -还是人类甚至不需要提供水冷和电力等硬件基础才算？
                <br/><br/>
                这样推断，我们会得出：任何一种号称Agentic的软硬件，最终都不够自动化和全权代理，因为人类几乎总得以某种方式介入其中。
                <br/><br/>
                相似地，今天的大语言模型比传统的计算机在任何程度上都更智能、更像人，那随便一个今天的Copilot，都比任何传统软硬件看起来更像Agent智能代理。
                <br/><br/>
                这是一场无聊的定义。如果人们为了商业目的创造这些观点，还情有可原，毕竟争论的目的是为了提高经济价值。
                <br/><br/>
                但认真地争论什么是Copilot，什么是Agent，就显得非常可笑。因为从经验主义的角度审视现实，这些观点都无法改变一个核心事实：人类依然是这场智能革命中不可或缺的元素，我们提供电力、芯片、硬件、算法和软件分销。人类占据着这个端到端系统的最核心位置——无论我们是上帝还是阶梯（用你想要的称呼就行），两种概念的细分对现实毫无影响。
                <br/><br/>
                <b><i>实验二：AGI（Artificial General
                    Intelligence，通用人工智能），是增强人类能力的工具？还是从根本上取代人类智慧的新物种？</i></b>
                <br/><br/>
                这两个态度表现了两种截然相反但深藏不露的思维气质，你甚至能从两者的偏好中认出文章刚开头描述的那两种哲学气质。
                <br/><br/>
                1. 人类创造了AGI，是我们创造了它，即使是上帝借由我们的双手创造了人工智能，我们拥有创造能力也正是我们拥有自由意志的象征。（理性，唯心的，乐观的自由意志）
                <br/><br/>
                2. AGI是开放宇宙的巨大计算器，人类只是宇宙通过更高效的物质计算通向另一种高级智慧形态的垫脚石（经验，唯物的，悲观的宿命论）
                <br/><br/>
                激进的唯物论强烈地强调世界的机械性，就像一切世间万物都被物理学公式编排完成，没有自由意志的存在，人的主观能动性为零，我们是硅片计算器的前置产物和进化环节。乐观的理性主义者认定真理的纯粹和简洁性，即使固执地认为连我们人都是上帝造出来的，自由意志依旧引导我们前进。
                <br/><br/>
                这两种观点激烈地争论世界是否还由人统治。但在实用主义者眼中并无分别，你怎么认为都是错的，或者都是对的。
                <br/><br/>
                这些炫目的词汇，对一个实用主义者而言意义颇微，这些讨论就像争论是“热质”还是“冷质”产生了热工循环一样毫无意义。因为无论是“热质”还是“冷质”，在认真的推理后，都无法避免地会通向同一个“热工转换守恒”的热力学第一定律。
                <br/><br/>
                但通过实用主义方法，让我们对AGI的事实进行万种可能的一种表达，或许是下面这样：
                <br/><br/>
                <ol>
                    <li>AGI对劳动力市场的冲击，会造成近期社会失业和再分工</li>
                    <li>同时，AGI会加深身份伪造，进一步引发社会动荡</li>
                    <li>最后，AGI会促进社会生产力大飞跃</li>
                </ol>
                在这个表述中，实用主义方法能提供实在的潜在威胁和可能的预防措施，但我需要强调：这绝不是通过实用主义能得出的唯一观点。实用主义只是手段，我所关注的问题被实用主义“走廊”引导到上述论点，但也有无数有意义的房间可以作为目的地。
                <br/><br/>
                但和信奉AGI取代人类还是增强人类的观点相比，这种实用主义的观点平息了无意义的争论，同时最大化组织起双方的智慧和努力。
                <br/><br/>
                这就是实用主义方法的力量。
                <br/><br/><br/>
                <h6><b>一种激进的实用主义</b></h6>
                <br/>
                实用主义的真正目的是让人们摆脱对过去的争论，曾经发生的事——唐宋的社会结构、宗教的中世纪、文艺复兴——等等，质问这些曾经运作过的世界究竟是唯心还是唯物的，不同观点的效果以惊人的方式重叠成毫无差别的等价态——这是庸人自扰的争辩。
                <br/><br/>
                无论今天如何认为，那个世界都以其特有的组织方式存在过了。
                <br/><br/>
                实用主义把对认知结果的关注转换成对认知方法的关注；类似地，实用主义把对过去的争论重新校准为对未来的展望——我们如何理解明天，讲述明天，塑造明天。
                <br/><br/>
                也就是说：无论浅显或深刻，人们能理解的一种知识的全部，就是这个人能运用于现实的全部，也就正是这种思想的全部，无论这种思想自称还有什么别的内涵和深度。
                <br/><br/>
                关注具体事实和实用方法，会以一种更直截了当但也更温和的方式，重新武装人在现实斗争中的手段。
                <br/><br/><br/>
                <i style={{color: "gray"}}>
                    2024.12.23<br/>
                    At a barber shop in San Diego
                </i>
                {/*while my M getting hair done.*/}

            </p>

            <br/>

            <h6 className="App-essay-title">A Strong Pragmatism: Reading William James</h6>
            <p className="App-essay-article">
                <br/>
                <b>Dec 2024</b>
                <br/><br/>

            </p>
        </div>)
}
